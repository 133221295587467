import React from 'react';
import { Message } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectOnboardingStepNum, selectIsOnboardingDocLoading } from '../../redux/onboarding/onboarding.selectors';
import { selectCurrentUser } from '../../redux/user/user.selectors';
import { getOnboardingDocStart } from '../../redux/onboarding/onboarding.actions';

import LoadingSpinner from '../../components/LoadingSpinner/LoadingSpinner.component';
import AppContainer from '../../components/AppContainer/AppContainer.component';
import WaitingList from '../../components/WaitingList/WaitingList.component';
import AdminPanel from '../../components/AdminPanel/AdminPanel.component';
import OnboardingSurvey from '../../components/OnboardingSurvey/OnboardingSurvey.component';

class OnboardingPage extends React.Component {

  componentDidMount() {
    const { currentUser, getOnboardingDocStart } = this.props;
    getOnboardingDocStart(currentUser);
  }
  
  renderOnboardingStep = () => {
    const { stepNum } = this.props;

    switch (stepNum) {
      case 0:
        // return <AppContainer><WaitingList /></AppContainer>;
        return <AdminPanel />;
      case 1:
        // return <AppContainer><OnboardingSurvey /></AppContainer>;
        return <AdminPanel />;
      case 2:
        return <AdminPanel />
      default:
        return <AppContainer>
          <Message>
            <Message.Content>Something went wrong. Please contact admin@salussms.com</Message.Content>
          </Message>
      </AppContainer>;
    }
  }

  render() {
    const { isOnboardingDocLoading } = this.props;
    return (
      <React.Fragment>
        { 
          isOnboardingDocLoading ? 
          (<AppContainer>
            <LoadingSpinner message="Setting things up..." />
          </AppContainer>) : (
            <React.Fragment>
            {this.renderOnboardingStep()}
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  stepNum: selectOnboardingStepNum,
  currentUser: selectCurrentUser,
  isOnboardingDocLoading: selectIsOnboardingDocLoading
});

const mapDispatchToProps = dispatch => ({
  getOnboardingDocStart: (currentUser) => dispatch(getOnboardingDocStart(currentUser)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingPage);