import React from 'react';
import { Container, Segment, Header, Divider, Button, Grid, List, Breadcrumb, Dropdown, Icon, Checkbox, Label, Message, Form } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { getEmployeesStart } from '../../redux/employees/employees.actions';
import { selectCurrentEmployees } from '../../redux/employees/employees.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { selectShiftsLoading, selectShiftsErrors } from '../../redux/shifts/shifts.selectors';
import { addShiftStart, addShiftFailure } from '../../redux/shifts/shifts.actions';

import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';
import ShiftAssignEmployeeModal from '../ShiftAssignEmployeeModal/ShiftAssignEmployeeModal.component';
import WorkdaysToggle from '../WorkdaysToggle/WorkdaysToggle.component';

// const timeZoneOptions = moment.tz.names().map(tz => ({ key: tz, value: tz, text: tz }));
const timeZoneOptions = moment.tz.zonesForCountry('US').map(tz => ({ key: tz, value: tz, text: tz }));

class ShiftCreate extends React.Component {
  state = {
    shiftName: '',
    shiftTimezone: moment.tz.guess(),
    shiftStart: '08:00',
    shiftSendAuto: true,
    shiftNotificationTime: 60,
    workdaysSelected: {
      MON: false,
      TUE: false,
      WED: false,
      THU: false,
      FRI: false,
      SAT: false,
      SUN: false,
    },

    assignEmployeeModalOpen: false,
    assignedEmployees: []
  }

  componentDidMount() {
    const { currentCompany, getEmployeesStart } = this.props;
    getEmployeesStart({ currentCompany });
  }

  toggleWorkday = (workday) => {
    const { workdaysSelected } = this.state;
    this.setState({
      workdaysSelected: {
        ...workdaysSelected,
        [workday]: !workdaysSelected[workday]
      }
    })
  }

  toggleAssignId = (employeeId) => {
    const { assignedEmployees } = this.state;
    let newIds = [ ...assignedEmployees, employeeId ];
    if (assignedEmployees.includes(employeeId)) {
      newIds = assignedEmployees.filter(id => id !== employeeId);
    }
    this.setState({ assignedEmployees: newIds });
  }

  updateAssignedEmployees = (employeeIds) => this.setState({ assignedEmployees: employeeIds });

  openAssignEmployeeModal = () => this.setState({ assignEmployeeModalOpen: true });
  closeAssignEmployeeModal = () => this.setState({ assignEmployeeModalOpen: false });

  atLeastOneWorkdayIsSelected = () => {
    const { workdaysSelected } = this.state;
    let oneSelected = false;
    Object.keys(workdaysSelected).forEach(workDayId => {
      if (workdaysSelected[workDayId]) oneSelected = true;
    })
    return oneSelected;
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentCompany, addShiftStart, addShiftFailure } = this.props;
    const { shiftName, shiftTimezone, shiftStart, shiftSendAuto, shiftNotificationTime, assignedEmployees, workdaysSelected } = this.state;
    
    if ( !shiftName || !shiftTimezone || !shiftStart || !this.atLeastOneWorkdayIsSelected() ) {
      addShiftFailure({
        code: "custom/failed-add-new-shift",
        message: "Please fill out all required fields"
      })
      return;
    }

    addShiftStart({ 
      currentCompany, 
      newShiftData: {
        shiftName, 
        shiftTimezone, 
        shiftStart, 
        shiftSendAuto,
        shiftNotificationTime,
        assignedEmployees,
        workdaysSelected
      }
    });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  renderEmployeeSublist = () => {
    const { assignedEmployees } = this.state;
    const { currentEmployees } = this.props
    return (
      <List divided verticalAlign='middle' animated size="large">
        { assignedEmployees.map(employeeId => (
          <List.Item key={employeeId}>
            <List.Content floated='right'>
              <Button 
                color={assignedEmployees.includes(employeeId) ? "green" : "blue"}
                icon={assignedEmployees.includes(employeeId) ? "check" : "plus"}
                labelPosition='left'
                content={assignedEmployees.includes(employeeId) ? "Unassign" : "Assign"}
                onClick={() => this.toggleAssignId(employeeId)}
              />
            </List.Content>
            <List.Icon name="user circle" size="large"/>
            <List.Content>
              {currentEmployees[employeeId].employeeName}
            </List.Content>
          </List.Item>
        )) }
      </List>
    );
  }

  render() {
    const { setCurrentView, currentEmployees, shiftsLoading, shiftsErrors } = this.props;
    const { 
      shiftName, shiftTimezone, shiftStart, shiftSendAuto, shiftNotificationTime, 
      assignEmployeeModalOpen, assignedEmployees, workdaysSelected
    } = this.state;

    return (
      <Container>

        <ShiftAssignEmployeeModal 
          isOpen={assignEmployeeModalOpen} 
          onClose={this.closeAssignEmployeeModal} 
          assignedIds={assignedEmployees} 
          currentEmployees={currentEmployees}
          toggleAssignId={this.toggleAssignId}
          onSubmit={(ids) => this.updateAssignedEmployees(ids)} 
        />

        <Breadcrumb>
          <Breadcrumb.Divider icon='left chevron' />
          <Breadcrumb.Section link onClick={() => setCurrentView("ShiftList")}>Back</Breadcrumb.Section>
        </Breadcrumb>

        <Header as="h1">
          <Header.Content>Create Shift</Header.Content>
        </Header>

        <Segment.Group>

          <Segment>

            <Grid columns={2} divided>
              <Grid.Row>
                <Grid.Column>

                  <Form size="large" onSubmit={this.handleSubmit} loading={!!shiftsLoading}>

                    <Header>Shift Settings</Header>

                    <Form.Input
                      fluid
                      label="Shift Name"
                      autoComplete="off"
                      icon='tag' 
                      iconPosition='left' 
                      placeholder='Enter a name for this shift e.g. Shift 1' 
                      required
                      name="shiftName" 
                      value={shiftName}
                      onChange={this.handleChange} 
                    />

                    <Form.Field required>
                      <label>Shift Timezone</label>
                      <Header style={{ marginTop: 0 }}><Header.Subheader>Current time in {shiftTimezone}: {moment().tz(shiftTimezone).format("h:mm A z")}</Header.Subheader></Header>
                      <Dropdown
                        autoComplete="off"
                        search
                        className="icon"
                        labeled
                        floating
                        selection
                        header='Select shift timezone'
                        options={timeZoneOptions}
                        name="shiftTimezone"
                        value={shiftTimezone}
                        onChange={this.handleSelectChange} 
                      />
                    </Form.Field>

                    <Form.Input
                      fluid
                      required
                      label="Shift Start Time"
                      type="time"
                      icon="clock"
                      iconPosition="left"
                      autoComplete="off"
                      name="shiftStart" 
                      value={shiftStart}
                      onChange={this.handleChange}
                    />

                    <Form.Field required>
                      <label>Shift Working Days</label>
                      <WorkdaysToggle 
                        workdaysSelected={workdaysSelected}
                        onToggleWorkday={this.toggleWorkday}
                      />
                    </Form.Field>
                  
                    <Divider hidden />

                    <Header>Survey Notification Settings</Header>

                    <Form.Field>
                      <Checkbox 
                        label='Automatically send surveys prior to shift start'
                        toggle
                        name="shiftSendAuto"
                        checked={shiftSendAuto}
                        onChange={() => this.setState({ shiftSendAuto: !shiftSendAuto })} 
                      />
                    </Form.Field>

                    <Form.Input
                      fluid
                      label={
                        <React.Fragment>
                        <label>Notification Schedule</label>
                        <Header style={{ marginTop: 0 }}>
                          <Header.Subheader>
                            Send surveys{' '}
                            {+shiftNotificationTime === 0 ? " exactly at " : (
                              <React.Fragment>
                              {+shiftNotificationTime > 0 ? +shiftNotificationTime : -+shiftNotificationTime} mins 
                              {+shiftNotificationTime > 0 ? " prior to " : " after "} 
                              </React.Fragment>
                            )}
                            shift start ({moment.tz(shiftStart,"HH:mm",shiftTimezone).subtract(+shiftNotificationTime, 'minutes').format("h:mm A")})
                          </Header.Subheader>
                        </Header>
                        </React.Fragment>
                      }
                      type="number"
                      icon="bell"
                      iconPosition="left"
                      autoComplete="off"
                      // placeholder='Employee Name' 
                      // required
                      name="shiftNotificationTime" 
                      value={shiftNotificationTime}
                      onChange={this.handleChange}
                    />

                  </Form>

                  {
                    shiftsErrors ? (
                      <Message error>
                        <Message.Header>Could not add shift.</Message.Header>
                        { renderCustomizedErrorMessage(shiftsErrors.error.code, shiftsErrors.error.message) }
                      </Message>
                    ) : null
                  }

                </Grid.Column>

                <Grid.Column>

                  {/* <Header>Linked Surveys</Header>

                  <List verticalAlign='middle'>
                    <List.Item>
                      <List.Content floated='right'>
                        <Button content="Unlink" color="red" icon="cancel" labelPosition="left" size="mini"/>
                      </List.Content>
                      <List.Content>
                        <List.Header>COVID-19 Self-Screening Survey</List.Header>
                        <List.Description as='a'>Automated Self-Serve SMS Survey</List.Description>
                      </List.Content>
                    </List.Item>
                  </List>

                  <Button content="Link new survey" size="mini" icon="plus" labelPosition="left" compact /> 

                  <Divider />

                  */}

                  <Header>
                    <Header.Content>Assign Employees</Header.Content>
                    <Header.Subheader>Note: Employees won't be reassigned until this shift is created.</Header.Subheader>
                  </Header>

                  <Button content="Manage Assigned Employees" icon="edit" labelPosition="left" onClick={this.openAssignEmployeeModal} />

                  { assignedEmployees ? (
                    this.renderEmployeeSublist()
                  ) : (
                    <Message>
                      <Message.Content>No employees are currently assigned to this shift</Message.Content>
                    </Message>
                  )}
                  
                  </Grid.Column>

              </Grid.Row>
            </Grid>

          </Segment>
          <Segment secondary>
            <Button color="green" content="Add Shift" labelPosition="left" icon="check" type="submit"
              loading={!!shiftsLoading} 
              disabled={!!shiftsLoading} 
              onClick={this.handleSubmit}
            />
            <Button color="red" content="Cancel" labelPosition="left" icon="cancel" onClick={() => setCurrentView("ShiftList")} type="button" />
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentEmployees: selectCurrentEmployees,
  shiftsLoading: selectShiftsLoading,
  shiftsErrors: selectShiftsErrors
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getEmployeesStart: ({ currentCompany }) => dispatch(getEmployeesStart({ currentCompany })),
  addShiftStart: ({ currentCompany, newShiftData }) => dispatch(addShiftStart({ currentCompany, newShiftData })),
  addShiftFailure: (error) => dispatch(addShiftFailure(error))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftCreate);