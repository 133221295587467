import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/auth';
import { cloudFunctions } from './cloudFunctions';

// Init firebase config var - this object will be assembled depending on build environment
let firebaseConfig;

// Read config file and switch firebase URL accordingly
const BUILD_LEVEL = require('./.build-config.json').BUILD_LEVEL;
if (BUILD_LEVEL === "dev") {
  firebaseConfig = {
    apiKey: "AIzaSyCi2753IHZf89eGTGq5RgLZlZNZVokJTnE",
    authDomain: "salus-dev.firebaseapp.com",
    databaseURL: "https://salus-dev.firebaseio.com",
    projectId: "salus-dev",
    storageBucket: "salus-dev.appspot.com",
    messagingSenderId: "520900809642",
    appId: "1:520900809642:web:2581a5e508ffdd22f7d1d3"
  };
} else if (BUILD_LEVEL === "stage") {
  firebaseConfig = {
    apiKey: "AIzaSyAknEx2dxSa1el-dw9QXyO1_POpqT340ik",
    authDomain: "salus-sms-stage-563fb.firebaseapp.com",
    databaseURL: "https://salus-sms-stage-563fb.firebaseio.com",
    projectId: "salus-sms-stage-563fb",
    storageBucket: "salus-sms-stage-563fb.appspot.com",
    messagingSenderId: "1019029198524",
    appId: "1:1019029198524:web:09ab4c5f3681fd249ce2b9"
  };
} else if (BUILD_LEVEL === "demo") {
  firebaseConfig = {
    apiKey: "AIzaSyAEwBdBZDrSalJQlkaEfEaNaNYLAwGuVvE",
    authDomain: "salus-demo.firebaseapp.com",
    databaseURL: "https://salus-demo.firebaseio.com",
    projectId: "salus-demo",
    storageBucket: "salus-demo.appspot.com",
    messagingSenderId: "324018547055",
    appId: "1:324018547055:web:d902f8ae431bf28d56a09a"
  };
} else if (BUILD_LEVEL === "prod") {
  firebaseConfig = {
    apiKey: "AIzaSyBlAovOMZKmdRRFOBfj2nBlrIEAWN7W_IU",
    authDomain: "salus-sms.firebaseapp.com",
    databaseURL: "https://salus-sms.firebaseio.com",
    projectId: "salus-sms",
    storageBucket: "salus-sms.appspot.com",
    messagingSenderId: "248650882412",
    appId: "1:248650882412:web:28fa3292a1ef7d6013a749"
  };
} else {
  console.error(`ATTENTION: No firebase build config exists for ${BUILD_LEVEL}`);
}

export const createUserProfileDocument = async (userAuth, additionalData) => {
  if(!userAuth) return;

  const userRef = firestore.doc(`users/${userAuth.uid}`);

  const snapShot = await userRef.get();

  if (!snapShot.exists) {
    const { displayName, email } = userAuth;
    const createdAt = new Date();

    try {
      await userRef.set({
        displayName,
        email,
        createdAt,
        ...additionalData
      })
    } catch (error) {
      console.error('error creating user', error.message);
    }
  }

  return userRef;
};

export const updateUserAttributes = async (currentUser, newAttributes) => {
  if(!currentUser) return;

  const userRef = firestore.collection('users').doc(currentUser.id);

  const snapShot = await userRef.get();

  if (snapShot.exists) {
    try {
      await userRef.update({
        ...newAttributes
      })
    } catch (error) {
      console.error('Error updating user attributes', error.message);
      return error;
    }
  } else {
    throw new Error("User does not exist");
  }

  return userRef;
};

export const updateUserOnboardingStep = async (currentUser, increment) => {
  if(!currentUser) return new Error("User is not signed in");

  const onboardingRef = firestore.collection('onboarding').doc(currentUser.id);

  let stepIncrement = increment || 1;

  try {
    await onboardingRef.set({
      onboardingStepNum: firebase.firestore.FieldValue.increment(stepIncrement)
    }, { merge: true });
  } catch (error) {
    console.error('Error updating step: ', error.message);
    return error;
  }

  return onboardingRef;
};

export const getUserOnboardingDocument = async (currentUser) => {
  if(!currentUser) return new Error("User is not signed in");
  const onboardingRef = firestore.collection('onboarding').doc(currentUser.id);
  try {
    const snapShot = await onboardingRef.get();
    if (snapShot.exists) {
      return snapShot.data();
    } else {
      // No document exists
      return null;
    }
  } catch (error) {
    console.error('Could not retrieve onboarding data: ', error.message);
    return error;
  }
};

export const updateUserOnboardingAttributes = async (currentUser, newAttributes) => {
  if(!currentUser) return new Error("User is not signed in");

  const onboardingRef = firestore.collection('onboarding').doc(currentUser.id);

  try {
    await onboardingRef.set({
      ...newAttributes
    }, { merge: true });
  } catch (error) {
    console.error('Error updating onboarding category values: ', error.message);
    return error;
  }

  return onboardingRef;
};

export const updateOnboardingCategoryValues = async (currentUser, categoryValues) => {
  if(!currentUser) return new Error("User is not signed in");;

  const onboardingRef = firestore.collection('onboarding').doc(currentUser.id);

  try {
    await onboardingRef.set({
      categoryValues
    }, { merge: true });
  } catch (error) {
    console.error('Error updating onboarding category values: ', error.message);
    return error;
  }

  return onboardingRef;
};

export const setUserGoal = async (currentUser, goalId, goalToSet) => {
  if(!currentUser) return new Error("User is not signed in");
  const isNewGoal = !goalId;

  try {
    // Generate document metadata and ref based on new document or not
    const lastModified = new Date();
    let additionalData = { lastModified }
    if (isNewGoal) {
      const createdAt = new Date();
      additionalData = {
        ...additionalData,
        createdAt
      };

      const goalsRef = firestore
        .collection('userGoals').doc(currentUser.id)
        .collection('goals')

      const newDocRef = await goalsRef.add({
        ...goalToSet,
        ...additionalData
      })
      .then(newDoc => {
        return newDoc;
      });

      return newDocRef;

    } else {
      const goalDocRef = firestore
        .collection('userGoals').doc(currentUser.id)
        .collection('goals').doc(goalId);
      await goalDocRef.set({
        ...goalToSet,
        ...additionalData
      }, { merge: true });
      return goalDocRef;
    }
  } catch (error) {
    console.error('Error updating goal data: ', error.message);
    return error;
  }
};

export const createInitialCompanyDocument = async (currentUser, companyAttributes) => {

  try { 
    const companyRef = firestore.collection('companies');
    const createdAt = new Date();
    const newCompanyId = await companyRef.add({
      admins: [ currentUser.id ],
      createdAt,
      ...companyAttributes,
      shifts: [],
      employees: []
    })
    .then(newDoc => {
      return newDoc.id;
    });
    await updateUserAttributes(currentUser, { linkedCompany: newCompanyId, onboardingCompleted: true, accountActivated: false });
    return newCompanyId;
  } catch (error) {
    console.error('Error creating initial company document: ', error.message);
    return error;
  }
};

export const getCompanyDocument = async (companyId) => {
  const companyRef = firestore.collection('companies').doc(companyId);
  try {
    const snapShot = await companyRef.get();
    if (snapShot.exists) {
      return snapShot.data();
    } else {
      // No document exists
      return null;
    }
  } catch (error) {
    console.error('Could not retrieve company data: ', error.message);
    return error;
  }
};

export const addEmployeeDocument = async (currentCompany, newEmployeeData) => {
  try {

    // First create the new employee
    const employeesRef = firestore.collection('employees');
    const createdAt = new Date();
    const newEmployeeId = await employeesRef.add({
      createdAt,
      ...newEmployeeData
    })
    .then(newDoc => {
      return newDoc.id;
    });

    // Now add this employee to the company employee list
    const companyRef = firestore.collection('companies').doc(currentCompany.companyId);
    await companyRef.update({
      employees: firebase.firestore.FieldValue.arrayUnion(newEmployeeId)
    });

    // Finally, update the linked shift assignedEmployees list
    if (newEmployeeData.shiftAssignment !== "Unassigned") {
      const shiftRef = firestore.collection('shifts').doc(newEmployeeData.shiftAssignment);
      await shiftRef.update({
        assignedEmployees: firebase.firestore.FieldValue.arrayUnion(newEmployeeId)
      });
    }

    // Return the new employee id
    return newEmployeeId;
  } catch (error) {
    console.error('Error creating new employee: ', error.message);
    return error;
  }
};

export const updateEmployeeDocument = async (employeeId, employeeData) => {
  try {

    // First, update the linked shift assignedEmployees
    if (employeeData.shiftAssignment !== "Unassigned") {
      const shiftRef = firestore.collection('shifts').doc(employeeData.shiftAssignment);
      await shiftRef.update({
        assignedEmployees: firebase.firestore.FieldValue.arrayUnion(employeeId)
      });
    }

    // Finally, update the employee document, including last modified date
    const employeeRef = firestore.collection('employees').doc(employeeId);
    const lastModified = new Date();
    const updateObject = {
      lastModified,
      ...employeeData
    };
    await employeeRef.update(updateObject);
    return updateObject;
  } catch (error) {
    console.error('Error updating employee: ', error.message);
    return error;
  }
};

export const deleteEmployeeDocument = async (currentCompany, employeeId) => {
  try {

    // First, catch the affected shift and remove the employee from the assignedEmployees list
    const employeeRef = firestore.collection('employees').doc(employeeId);
    const priorEmployeeRef = await employeeRef.get();
    const priorEmployeeData = priorEmployeeRef.data();
    const shiftAssignment = priorEmployeeData.shiftAssignment;
    if (shiftAssignment && shiftAssignment !== "Unassigned") {
      const shiftRef = firestore.collection('shifts').doc(shiftAssignment);
      await shiftRef.update({
        assignedEmployees: firebase.firestore.FieldValue.arrayRemove(employeeId)
      });
    }

    // Now delete this employee from the company employee list
    const companyRef = firestore.collection('companies').doc(currentCompany.companyId);
    await companyRef.update({
      employees: firebase.firestore.FieldValue.arrayRemove(employeeId)
    });

    // Finally, delete the new employee from the employees collection
    await employeeRef.delete();

    // Return the new employee id
    return employeeId;
  } catch (error) {
    console.error('Error deleting employee: ', error.message);
    return error;
  }
};

export const getCurrentEmployeeDocuments = async (currentCompany) => {
  const employeesToQuery = currentCompany.employees;
  if (!employeesToQuery) return null;
  try {
    const employeeRefs = employeesToQuery.map(employeeId => firestore.collection('employees').doc(employeeId).get());
    return Promise.all(employeeRefs)
    .then(employeeDocs => {
      let employeeData = {};
      employeeDocs.forEach((doc) => {
        employeeData[doc.id] = doc.data();
      });
      return employeeData;
    });
  } catch (error) {
    console.error('Could not retrieve employee data: ', error.message);
    return error;
  }
};

/////// Shifts

export const addShiftDocument = async (currentCompany, newShiftData) => {
  try {

    // First create the new shift
    const shiftsRef = firestore.collection('shifts');
    const createdAt = new Date();
    const newShiftId = await shiftsRef.add({
      createdAt,
      ...newShiftData
    })
    .then(newDoc => {
      return newDoc.id;
    });

    // Now add this shift to the company shift list
    const companyRef = firestore.collection('companies').doc(currentCompany.companyId);
    await companyRef.update({
      shifts: firebase.firestore.FieldValue.arrayUnion(newShiftId)
    });

    // Finally, update each employee shift assignment, if any
    if (newShiftData.assignedEmployees.length) {
      const updateEmployeeShiftRefs = newShiftData.assignedEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, newShiftId));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Return the new shift id
    return newShiftId;
  } catch (error) {
    console.error('Error creating new shift: ', error.message);
    return error;
  }
};

export const updateShiftDocument = async (shiftId, shiftData) => {
  try {
    
    // First, catch any employees who have been removed from the shift and update them to Unassigned
    const shiftRef = firestore.collection('shifts').doc(shiftId);
    const priorShiftRef = await shiftRef.get();
    const priorShiftData = priorShiftRef.data();
    const droppedEmployees = priorShiftData.assignedEmployees.filter(id => !shiftData.assignedEmployees.includes(id));
    if (droppedEmployees.length) {
      const updateEmployeeShiftRefs = droppedEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, "Unassigned"));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Next, catch any newly assigned employees to this shift, if any
    const newEmployees = shiftData.assignedEmployees.filter(id => !priorShiftData.assignedEmployees.includes(id));
    if (newEmployees.length) {
      const updateEmployeeShiftRefs = newEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, shiftId));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Finally, update the shift document, including last modified date
    const lastModified = new Date();
    const updateObject = {
      lastModified,
      ...shiftData
    };
    await shiftRef.update(updateObject);

    return updateObject;
  } catch (error) {
    console.error('Error updating shift: ', error.message);
    return error;
  }
};

export const deleteShiftDocument = async (currentCompany, shiftId) => {
  try {

    // First, catch any employees who have been removed from the old shift and update them to Unassigned
    const shiftRef = firestore.collection('shifts').doc(shiftId);
    const priorShiftRef = await shiftRef.get();
    const priorShiftData = priorShiftRef.data();
    const droppedEmployees = priorShiftData.assignedEmployees;
    if (droppedEmployees.length) {
      const updateEmployeeShiftRefs = droppedEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, "Unassigned"));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Next, delete this shift from the company shift list
    const companyRef = firestore.collection('companies').doc(currentCompany.companyId);
    await companyRef.update({
      shifts: firebase.firestore.FieldValue.arrayRemove(shiftId)
    });

    // Finally, delete the new shift from the shifts collection
    await shiftRef.delete();

    // Return the new shift id
    return shiftId;
  } catch (error) {
    console.error('Error deleting shift: ', error.message);
    return error;
  }
};

export const getCurrentShiftDocuments = async (currentCompany) => {
  const shiftsToQuery = currentCompany.shifts;
  if (!shiftsToQuery) return null;
  try {
    const shiftRefs = shiftsToQuery.map(shiftId => firestore.collection('shifts').doc(shiftId).get());
    return Promise.all(shiftRefs)
    .then(shiftDocs => {
      let shiftData = {};
      shiftDocs.forEach((doc) => {
        shiftData[doc.id] = doc.data();
      });
      return shiftData;
    });
  } catch (error) {
    console.error('Could not retrieve shift data: ', error.message);
    return error;
  }
};

export const updateEmployeeShiftAssignment = async (employeeId, shiftId) => {
  try {
    // Update the employee document, including last modified date
    const employeeRef = firestore.collection('employees').doc(employeeId);
    const lastModified = new Date();
    const updateObject = {
      lastModified,
      shiftAssignment: shiftId || "Unassigned"
    };
    await employeeRef.update(updateObject);
    return updateObject;
  } catch (error) {
    console.error('Error updating employee shift assignment: ', error.message);
    return error;
  }
};

// Surveys

export const addSurveyDocument = async (currentCompany, newShiftData) => {
  try {

    // First create the new shift
    const shiftsRef = firestore.collection('shifts');
    const createdAt = new Date();
    const newShiftId = await shiftsRef.add({
      createdAt,
      ...newShiftData
    })
    .then(newDoc => {
      return newDoc.id;
    });

    // Now add this shift to the company shift list
    const companyRef = firestore.collection('companies').doc(currentCompany.companyId);
    await companyRef.update({
      shifts: firebase.firestore.FieldValue.arrayUnion(newShiftId)
    });

    // Finally, update each employee shift assignment, if any
    if (newShiftData.assignedEmployees.length) {
      const updateEmployeeShiftRefs = newShiftData.assignedEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, newShiftId));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Return the new shift id
    return newShiftId;
  } catch (error) {
    console.error('Error creating new shift: ', error.message);
    return error;
  }
};

export const updateSurveyDocument = async (shiftId, shiftData) => {
  try {
    
    // First, catch any employees who have been removed from the shift and update them to Unassigned
    const shiftRef = firestore.collection('shifts').doc(shiftId);
    const priorShiftRef = await shiftRef.get();
    const priorShiftData = priorShiftRef.data();
    const droppedEmployees = priorShiftData.assignedEmployees.filter(id => !shiftData.assignedEmployees.includes(id));
    if (droppedEmployees.length) {
      const updateEmployeeShiftRefs = droppedEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, "Unassigned"));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Next, catch any newly assigned employees to this shift, if any
    const newEmployees = shiftData.assignedEmployees.filter(id => !priorShiftData.assignedEmployees.includes(id));
    if (newEmployees.length) {
      const updateEmployeeShiftRefs = newEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, shiftId));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Finally, update the shift document, including last modified date
    const lastModified = new Date();
    const updateObject = {
      lastModified,
      ...shiftData
    };
    await shiftRef.update(updateObject);

    return updateObject;
  } catch (error) {
    console.error('Error updating shift: ', error.message);
    return error;
  }
};

export const deleteSurveyDocument = async (currentCompany, shiftId) => {
  try {

    // First, catch any employees who have been removed from the old shift and update them to Unassigned
    const shiftRef = firestore.collection('shifts').doc(shiftId);
    const priorShiftRef = await shiftRef.get();
    const priorShiftData = priorShiftRef.data();
    const droppedEmployees = priorShiftData.assignedEmployees;
    if (droppedEmployees.length) {
      const updateEmployeeShiftRefs = droppedEmployees.map(employeeId => updateEmployeeShiftAssignment(employeeId, "Unassigned"));
      await Promise.all(updateEmployeeShiftRefs);
    }

    // Next, delete this shift from the company shift list
    const companyRef = firestore.collection('companies').doc(currentCompany.companyId);
    await companyRef.update({
      shifts: firebase.firestore.FieldValue.arrayRemove(shiftId)
    });

    // Finally, delete the new shift from the shifts collection
    await shiftRef.delete();

    // Return the new shift id
    return shiftId;
  } catch (error) {
    console.error('Error deleting shift: ', error.message);
    return error;
  }
};

export const getCurrentSurveyDocuments = async (currentCompany) => {
  try {

    // Access linked company Id
    const linkedCompany = currentCompany.companyId;

    // Get last survey promptId if it exists
    const surveyRef = firestore.collection('surveys').where("linkedCompany", "==", linkedCompany);
    const surveyDocs = await surveyRef.get();
    if (!surveyDocs) return ({});

    // Build return object - exclude sensitive data
    let returnObject = {};
    surveyDocs.forEach(doc => {
      const surveyDoc = doc.data();
      const docId = doc.id;
      returnObject[docId] = surveyDoc;
    });

    // Return result to client
    return (returnObject);

  } catch (error) {
    const eMsg = 'Could not retrieve surveys';
    console.error(eMsg,error.message);
    throw new Error(eMsg);
  }
};

export const toggleSurveyDocument = async (templateId, shiftId, nextStatus) => {
  try {
    const result = await functions.httpsCallable("toggleSurveyDocument")({
      templateId, shiftId, nextStatus
    });
    return result.data;
  } catch (error) {
    const eMsg = 'Error updating survey status: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

// Access code tokens

export const unlockAccessWithPassCode = async (currentUser, passCode) => {
  try {
    // TBD check access code securely on DB, also pause user and exchange a token
    // For now, let's do this quick and easy because it's not a big deal
    return cloudFunctions.post('unlockAccessWithPassCode', {
      currentUser,
      passCode
    }).then(res => {
      return res.data;
    });
  } catch (error) {
    console.error('Error checking access code: ', error.message);
    return error;
  }
}

export const generateSignupTokenAndNotify = async (currentUser, newCompanyId) => {
  try {
    // TBD check access code securely on DB, also pause user and exchange a token
    // For now, let's do this quick and easy because it's not a big deal
    return cloudFunctions.post('generateSignupTokenAndNotify', {
      currentUser,
      newCompanyId
    }).then(res => {
      return res.data;
    });
  } catch (error) {
    console.error('Error generating access token and signup notification emails: ', error.message);
    return error;
  }
}

export const initiateManualSurvey = async (surveyId) => {
  try {
    const result = await functions.httpsCallable("initiateManualSurvey")({
      surveyId
    });
    return result.data;
  } catch (error) {
    const eMsg = 'Error initiating new survey: ' + error.message;
    console.error(eMsg);
    throw new Error(eMsg);
  }
};

// Get prompts and responses
export const getCurrentPromptsAndResponses = async (currentCompany) => {
  try {

    // Access linked company Id
    const linkedCompany = currentCompany.companyId;

    // Retrieve all linked prompts, if any
    // console.log("Collecting prompts");
    const promptsRef = firestore.collection('prompts').where("linkedCompany", "==", linkedCompany);
    const promptsDocs = await promptsRef.get();
    if (!promptsDocs) return ({
      currentPrompts: {},
      currentResponses: {}
    });

    // Build return object - exclude sensitive data
    // console.log("Building prompts documents");
    let linkedResponseIds = [];
    let returnPromptsObject = {};
    promptsDocs.forEach(doc => {
      const promptDoc = doc.data();
      const docId = doc.id;
      returnPromptsObject[docId] = promptDoc;
      const newIds = promptDoc.responseIds.filter(id => !!id);
      if (newIds) {
        if (newIds.length) {
          linkedResponseIds.push(...newIds);
        }
      }
    });

    // Retrieve all response documents
    // console.log("Collecting responses");
    const responsesRef = firestore.collection('responses');
    const responsePromises = linkedResponseIds.map(responseId => responsesRef.doc(responseId).get());
    const responseDocuments = await Promise.all(responsePromises);

    // Build response documents
    // console.log("Building response documents");
    let returnResponsesObject = {};
    responseDocuments.forEach(doc => {
      const responseDoc = doc.data();
      const docId = doc.id;
      returnResponsesObject[docId] = responseDoc;
    });

    // Return result to client
    return ({
      currentPrompts: returnPromptsObject,
      currentResponses: returnResponsesObject
    });

  } catch (error) {
    const eMsg = 'Error retrieving survey responses';
    console.error(eMsg, error);
    throw new Error(eMsg);
  }
};

// export const addCollectionAndDocuments = async (collectionKey, objectsToAdd) => {
//   const collectionRef = firestore.collection(collectionKey);
//   console.log(collectionRef);

//   console.log("objectsToAdd: ",objectsToAdd);

//   const batch = firestore.batch();
//   objectsToAdd.forEach(obj => {
//     const newDocRef = collectionRef.doc(); // generates a new ID randomly
//     batch.set(newDocRef, obj)
//   });

//   return await batch.commit();
// };

// export const convertCollectionsSnapshotToMap = (collections) => {
//   const transformedCollection = collections.docs.map(doc => {
//     const { title, items } = doc.data();

//     return {
//       routeName: encodeURI(title.toLowerCase()),
//       id: doc.id,
//       title,
//       items
//     }
//   });

//   return transformedCollection.reduce((accumulator, collection) => {
//     accumulator[collection.title.toLowerCase()] = collection;
//     return accumulator;
//   }, {});
// };

export const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const unsubscribe = auth.onAuthStateChanged(userAuth => {
      unsubscribe();
      resolve(userAuth);
    }, reject);
  })
}

firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const functions = firebase.functions();

export const googleProvider = new firebase.auth.GoogleAuthProvider();
googleProvider.setCustomParameters({ prompt: 'select_account' });
export const signInWithGoogle = () => auth.signInWithPopup(googleProvider);

export default firebase;