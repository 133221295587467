import SurveysActionTypes from './surveys.types';

// Add survey

export const addSurveyStart = ({ currentCompany, newSurveyData }) => ({
  type: SurveysActionTypes.ADD_SURVEY_START,
  payload: {
    currentCompany,
    newSurveyData
  }
});

export const addSurveySuccess = ({ newSurveyId, newSurveyData }) => ({
  type: SurveysActionTypes.ADD_SURVEY_SUCCESS,
  payload: {
    newSurveyId,
    newSurveyData
  }
});

export const addSurveyFailure = error => ({
  type: SurveysActionTypes.ADD_SURVEY_FAILURE,
  payload: error
});

// Get surveys

export const getSurveysStart = (linkedCompany) => ({
  type: SurveysActionTypes.GET_SURVEYS_START,
  payload: { linkedCompany }
});

export const getSurveysSuccess = (currentSurveys) => ({
  type: SurveysActionTypes.GET_SURVEYS_SUCCESS,
  payload: {
    currentSurveys
  }
});

export const getSurveysFailure = error => ({
  type: SurveysActionTypes.GET_SURVEYS_FAILURE,
  payload: error
});

// Update survey

export const updateSurveyStart = ({ surveyId, surveyData, currentCompany }) => ({
  type: SurveysActionTypes.UPDATE_SURVEY_START,
  payload: {
    surveyId,
    surveyData,
    currentCompany
  }
});

export const updateSurveySuccess = ({ surveyId, surveyData }) => ({
  type: SurveysActionTypes.UPDATE_SURVEY_SUCCESS,
  payload: {
    surveyId,
    surveyData
  }
});

export const updateSurveyFailure = error => ({
  type: SurveysActionTypes.UPDATE_SURVEY_FAILURE,
  payload: error
});

// Delete survey

export const deleteSurveyStart = ({ currentCompany, currentSurveys, surveyId }) => ({
  type: SurveysActionTypes.DELETE_SURVEY_START,
  payload: {
    currentCompany,
    currentSurveys,
    surveyId
  }
});

export const deleteSurveySuccess = ({ surveyData, removedId }) => ({
  type: SurveysActionTypes.DELETE_SURVEY_SUCCESS,
  payload: {
    surveyData,
    removedId
  }
});

export const deleteSurveyFailure = error => ({
  type: SurveysActionTypes.DELETE_SURVEY_FAILURE,
  payload: error
});

// Toggle survey

export const toggleSurveyStart = ({ templateId, shiftId, nextStatus }) => ({
  type: SurveysActionTypes.TOGGLE_SURVEY_START,
  payload: {
    templateId, 
    shiftId, 
    nextStatus
  }
});

export const toggleSurveySuccess = (surveyData) => ({
  type: SurveysActionTypes.TOGGLE_SURVEY_SUCCESS,
  payload: surveyData
});

export const toggleSurveyFailure = error => ({
  type: SurveysActionTypes.TOGGLE_SURVEY_FAILURE,
  payload: error
});

// Initiate manual survey

export const manualSurveyStart = (surveyId) => ({
  type: SurveysActionTypes.MANUAL_SURVEY_START,
  payload: {
    surveyId,
  }
});

export const manualSurveySuccess = (surveyData) => ({
  type: SurveysActionTypes.MANUAL_SURVEY_SUCCESS,
  payload: surveyData
});

export const manualSurveyFailure = error => ({
  type: SurveysActionTypes.MANUAL_SURVEY_FAILURE,
  payload: error
});

// Get survey prompts

export const getPromptsStart = (linkedCompany) => ({
  type: SurveysActionTypes.GET_PROMPTS_START,
  payload: { linkedCompany }
});

export const getPromptsSuccess = ({ currentSurveys, currentPrompts, currentResponses }) => ({
  type: SurveysActionTypes.GET_PROMPTS_SUCCESS,
  payload: {
    currentSurveys, 
    currentPrompts, 
    currentResponses
  }
});

export const getPromptsFailure = error => ({
  type: SurveysActionTypes.GET_PROMPTS_FAILURE,
  payload: error
});