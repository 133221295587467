import React from 'react';
import { Container, Segment, Header, Button, List, Icon, Label, Message } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';

import WorkdaysList from '../WorkdaysList/WorkdaysList.component';

class ShiftList extends React.Component {
  componentDidMount() {
    const { currentCompany, getShiftsStart } = this.props;
    getShiftsStart({ currentCompany });
  }

  renderShiftSublist = (shiftList) => {
    const { setCurrentView } = this.props;
    const shiftIds = Object.keys(shiftList);
    return (
      <List divided relaxed verticalAlign='middle' animated size="large">
        { shiftIds.map(shiftId => (
          <List.Item key={shiftId}>
            <List.Content floated='right'>
              <Button onClick={() => setCurrentView("ShiftEdit", { shiftId })}>Manage</Button>
            </List.Content>
            <List.Icon name="calendar alternate" size="large"/>
            <List.Content>
              <List.Header>{shiftList[shiftId].shiftName}
              <WorkdaysList workdaysSelected={shiftList[shiftId].workdaysSelected} />
              </List.Header>
              <List.Description>
                {/* .subtract(+shiftNotificationTime, 'minutes') */}
                <div className="shiftDescriptionWrapper">
                  <Label size="mini" style={{ marginLeft: '3px' }} image basic>
                    <Icon name='stopwatch' />
                    Starts
                    <Label.Detail>{moment.tz(shiftList[shiftId].shiftStart,"HH:mm",shiftList[shiftId].shiftTimezone).format("h:mm A z")}</Label.Detail>
                  </Label>
                  { shiftList[shiftId].shiftSendAuto ? (
                    <Label size="mini" style={{ marginLeft: '3px' }} image basic>
                      <Icon name='send' />
                      Autosend
                      <Label.Detail>{moment.tz(shiftList[shiftId].shiftStart,"HH:mm",shiftList[shiftId].shiftTimezone).subtract(+shiftList[shiftId].shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                    </Label>
                ) : null }
                </div>
              </List.Description>
              <List.Description>{`${shiftList[shiftId].assignedEmployees.length || "No"} assigned employees`}</List.Description>
            </List.Content>
          </List.Item>
        )) }
      </List>
    );
  }

  renderShiftList = () => {
    const { currentShifts, shiftsLoading } = this.props;
    if (!currentShifts) {
      if (!!shiftsLoading) return null;
      return <Message>Looks like you haven't added any shifts yet. Add some using the button below!</Message>;
    } else {
      const currentShiftIds = Object.keys(currentShifts);
      if (!currentShiftIds.length) return <Message>Looks like you haven't added any shifts yet. Add some using the button below!</Message>;
      return (
        <React.Fragment>
        {currentShiftIds.length ? (
          this.renderShiftSublist(currentShifts)
        ) : null}
        </React.Fragment>
      );
    }
  }

  render() {
    const { setCurrentView, shiftsLoading } = this.props;

    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Shifts</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Shift Schedule</Header.Content>
            </Header>
          </Segment>

          <Segment loading={shiftsLoading}>
            {this.renderShiftList()}
          </Segment>
          <Segment secondary>
            <Button color="blue" content="Add New Shift" labelPosition="left" icon="add" onClick={() => setCurrentView("ShiftCreate")}/>
          </Segment>
        </Segment.Group>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftList);