import { takeLatest, put, all, call } from 'redux-saga/effects';

import SurveysActionTypes from './surveys.types';

import { 
  addSurveySuccess, addSurveyFailure,
  getSurveysStart, getSurveysSuccess, getSurveysFailure,
  updateSurveySuccess, updateSurveyFailure,
  deleteSurveySuccess, deleteSurveyFailure, toggleSurveyFailure, toggleSurveySuccess, 
  manualSurveySuccess, manualSurveyFailure, getPromptsSuccess, getPromptsFailure
} from './surveys.actions';

import { setCurrentView } from '../view/view.actions';

import {
  addSurveyDocument, getCurrentSurveyDocuments, updateSurveyDocument, deleteSurveyDocument,
  toggleSurveyDocument, initiateManualSurvey, getCurrentPromptsAndResponses
} from '../../firebase/firebase.utils';

export function* addSurvey({ payload: { currentCompany, newSurveyData } }) {
  try {
    const newSurveyId = yield addSurveyDocument(currentCompany, newSurveyData);
    yield put(addSurveySuccess({ newSurveyId, newSurveyData }));
    yield put(setCurrentView("SurveyList"));
  } catch (error) {
    yield put(addSurveyFailure(error));
  }
}

export function* onAddSurveyStart() {
  yield takeLatest(SurveysActionTypes.ADD_SURVEY_START, addSurvey);
}

export function* getCurrentSurveys({ payload: { linkedCompany } }) {
  try {
    const currentSurveys = yield getCurrentSurveyDocuments(linkedCompany);
    yield put(getSurveysSuccess(currentSurveys));
  } catch (error) {
    yield put(getSurveysFailure(error));
  }
}

export function* onGetCurrentSurveys() {
  yield takeLatest(SurveysActionTypes.GET_SURVEYS_START, getCurrentSurveys);
}

export function* updateSurvey({ payload: { surveyId, surveyData, currentCompany } }) {
  try {
    yield updateSurveyDocument(surveyId, surveyData);
    yield put(updateSurveySuccess({ surveyId, surveyData }));
    yield put(getSurveysStart({ currentCompany }));
  } catch (error) {
    yield put(updateSurveyFailure(error));
  }
}

export function* onUpdateSurveyStart() {
  yield takeLatest(SurveysActionTypes.UPDATE_SURVEY_START, updateSurvey);
}

export function* deleteSurvey({ payload: { currentCompany, currentSurveys, surveyId } }) {
  try {
    yield deleteSurveyDocument(currentCompany, surveyId);
    let newSurveyData = { ...currentSurveys };
    delete newSurveyData[surveyId];
    yield put(deleteSurveySuccess({ surveyData: newSurveyData, removedId: surveyId }));
    yield put(setCurrentView("SurveyList"));
  } catch (error) {
    yield put(deleteSurveyFailure(error));
  }
}

export function* onDeleteSurvey() {
  yield takeLatest(SurveysActionTypes.DELETE_SURVEY_START, deleteSurvey);
}

export function* toggleSurvey({ payload: { templateId, shiftId, nextStatus } }) {
  try {
    const surveyData = yield toggleSurveyDocument(templateId, shiftId, nextStatus);
    yield put(toggleSurveySuccess(surveyData));
  } catch (error) {
    yield put(toggleSurveyFailure(error));
  }
}

export function* onToggleSurvey() {
  yield takeLatest(SurveysActionTypes.TOGGLE_SURVEY_START, toggleSurvey);
}

export function* manualSurvey({ payload: { surveyId } }) {
  try {
    const surveyData = yield initiateManualSurvey(surveyId);
    yield put(manualSurveySuccess(surveyData));
  } catch (error) {
    yield put(manualSurveyFailure(error));
  }
}

export function* onManualSurvey() {
  yield takeLatest(SurveysActionTypes.MANUAL_SURVEY_START, manualSurvey);
}

export function* getCurrentPrompts({ payload: { linkedCompany } }) {
  try {
    const currentSurveys = yield getCurrentSurveyDocuments(linkedCompany);
    const { currentPrompts, currentResponses } = yield getCurrentPromptsAndResponses(linkedCompany);
    yield put(getPromptsSuccess({ currentSurveys, currentPrompts, currentResponses }));
  } catch (error) {
    yield put(getPromptsFailure(error));
  }
}

export function* onGetCurrentPrompts() {
  yield takeLatest(SurveysActionTypes.GET_PROMPTS_START, getCurrentPrompts);
}

export function* surveysSagas() {
  yield all([
    call(onAddSurveyStart),
    call(onGetCurrentSurveys),
    call(onUpdateSurveyStart),
    call(onDeleteSurvey),
    call(onToggleSurvey),
    call(onManualSurvey),
    call(onGetCurrentPrompts)
  ]);
}