import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button,
  Container,
  Divider,
  Grid,
  Header,
  Icon,
  Image,
  Segment,
  List
} from 'semantic-ui-react';

import { selectCurrentUser } from '../../redux/user/user.selectors';
import { signOutStart } from '../../redux/user/user.actions';
import BrandColors from '../../utils/brandColors';
import './HomePage.styles.css';
import AppContainer from '../../components/AppContainer/AppContainer.component';

/* eslint-disable react/no-multi-comp */
/* Heads up! HomepageHeading uses inline styling, however it's not the best practice. Use CSS or styled components for
 * such things.
 */
const HomepageHeading = ({ mobile }) => (
  <div className="heroBackground" style={{ paddingBottom: '32px' }}>
    <Container text 
      textAlign="center"
      style={{
        paddingTop: mobile ? '2em' : '4em',
        paddingBottom: mobile ? '2em' : '4em'
      }}
      className="whiteFade"
    >
      <Header
        as='h2'
        content={
        <React.Fragment>
          <span style={{color: BrandColors.HEX_COLOR_PRIMARY}}>Self screening </span> 
          is essential for safe return to work.
        </React.Fragment>}
        style={{
          fontSize: mobile ? '1.2em' : '3em',
          fontWeight: '700',
          marginTop: mobile ? '0.5em' : '0.5em',
          marginBottom: '0em'
        }}
      />
      <Header
        as='h2'
        content={<React.Fragment>SalusSMS delivers COVID-19<br/>self screening surveys via text message.</React.Fragment>}
        style={{
          fontSize: mobile ? '1.5em' : '1.7em',
          fontWeight: 'normal',
          marginTop: mobile ? '1em' : '1em',
          marginBottom: '2em'
        }}
      />
      <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='huge' className="round">
        Get Started
        <Icon name='right chevron' />
      </Button>
    </Container>
  </div>
)

HomepageHeading.propTypes = {
  mobile: PropTypes.bool,
}

const HomePage = (props) => (
  <AppContainer {...props}>

    <HomepageHeading {...props} />
    
    <Segment style={{ padding: '0em 0em 8em 0em' }} vertical className="white">

      <Grid container stackable verticalAlign='middle'>
        <Grid.Row>
          <Grid.Column floated='left' width={6}>
            {/* <Image bordered rounded size='large' src='https://miro.medium.com/max/4800/1*8tepPRU5zl9dhw8IysCtXw.gif' /> */}
            <Image src={'media/illustrations/iphone-hero-feature.png'} centered style={{ }} />
          </Grid.Column>
          <Grid.Column width={8}>
            <Header as='h3' style={{ fontSize: '3em' }}>
              Simplicity<span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>.</span>
            </Header>
            <List as='ul' size="huge">
              <List.Item as='li'>Receive "at-risk" or "clear to work" results instantaneously</List.Item>
              <List.Item as='li'>Launch surveys automatically for an entire team</List.Item>
              <List.Item as='li'>Easily review results and manage team data</List.Item>
              <List.Item as='li'>Start surveys manually at any time with a single text message</List.Item>
              <List.Item as='li'>No smartphone app or download required</List.Item>
              
            </List>

            <Header as='h3' style={{ fontSize: '3em' }}>
              Scale<span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>.</span>
            </Header>
            <List as='ul' size="huge">
              <List.Item as='li'>Reach all your employees and contractors via text message</List.Item>
              <List.Item as='li'>Customize for shift schedules, language, and local laws</List.Item>
              <List.Item as='li'>Cost-effective compliance with state requirements</List.Item>
              <List.Item as='li'>Reliable solution - even with poor cell phone reception</List.Item>
            </List>

            {/* <Header as='h3' style={{ fontSize: '2em' }}>
              But that's not it. We will even PAY YOU to help others achieve, too. <span style={{ color: BrandColors.HEX_COLOR_SUCCESS, fontWeight: 'bold' }}>Seriously.</span>
            </Header>
            <p style={{ fontSize: '1.33em' }}>
              People are going to start asking you how you made it all happen.
              Send a referal code, and <strong>get $5 / month for every referred friend</strong> who sticks to the program.
              It's all gravy... er, pie.
              <br />
              <br />
              <strong>You're going to have a <i style={{color: BrandColors.HEX_COLOR_PRIMARY }}>ridiculously successful</i> crew.</strong>
            </p> */}

          </Grid.Column>
        </Grid.Row>

        {/* <Grid.Row>
          <Grid.Column textAlign='center'>
            <Divider horizontal style={{
              marginBottom: '4em'
            }}>FOR THOSE WHO JUST CAN'T WAIT</Divider>
            <Button as={Link} to="/signup" size='huge' color={BrandColors.SEMANTIC_COLOR_PRIMARY} className="round">
              Here's a ridiculously large slice of signup button for you
              <Icon name='right chevron' />
            </Button>
          </Grid.Column>
        </Grid.Row> */}

      </Grid>
    </Segment>

    <div className="quoteBackground">
      <Segment style={{ padding: '10em 0' }} vertical inverted className="darkImageOverlay">
        <Grid celled='internally' columns='equal' stackable>
          <Grid.Row textAlign='center'>
            {/* <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              <Header as='h3' style={{ fontSize: '2em' }}>
                "What a Company"
              </Header>
              <p style={{ fontSize: '1.33em' }}>That is what they all say about us</p>
            </Grid.Column> */}
            <Grid.Column style={{ paddingBottom: '5em', paddingTop: '5em' }}>
              <Header as='h3' style={{ fontSize: '2em' }} inverted>
                27 states are already requiring<br/>some or all businesses<br/>to screen employees<br/>for COVID-19 symptoms.
              </Header>
              <p style={{ fontSize: '1.33em' }}>
                {/* <Image avatar src='https://react.semantic-ui.com/images/avatar/large/nan.jpg' /> */}
                {/* <b>-</b> John F. Kennedy <br/>definitely did not say this */}
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </div>

    <Segment style={{ padding: '8em 0em' }} vertical className="white">
      <Container text>
        <Header as='h3' style={{ fontSize: '2em' }}>
          We built Salus for the safety <br/> and well-being of our communities.
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Contact us at <a>Hello@SalusSMS.com</a> to hear more.
        </p>

        {/* <Header as='h3' style={{ fontSize: '2em' }}>
          ... so every day can be Pie Day?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Now you're getting it!
        </p> */}

        {/* <Divider
          as='h4'
          className='header'
          horizontal
          style={{ margin: '3em 0em', textTransform: 'uppercase' }}
        >
          <p style={{ color: "#4183c4" }}>SO ARE YOU READY NOW?</p>
        </Divider>

        <Header as='h3' style={{ fontSize: '2em' }}>
          Are you ready to become a goal-eating, <br/>pie-smashing, <i>achievement machine<span style={{ color: BrandColors.HEX_COLOR_PRIMARY }}>?</span></i>
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          This is serious work. This can change your life. Are you ready?
        </p>
        <Button as={Link} to="/signup" color={BrandColors.SEMANTIC_COLOR_PRIMARY} size='large' icon="arrow right" labelPosition="right" content="I was BORN ready" /> */}

        {/* <Header as='h3' style={{ fontSize: '2em' }}>
          Got a question?
        </Header>
        <p style={{ fontSize: '1.33em' }}>
          Ask away, but you better hurry! We're running out of slices.
        </p>
        <Button size='large' content="Ask Us a Question" /> */}

        <Divider hidden />
        <Divider hidden />
        <Divider hidden />

        {/* <p style={{fontSize: '0.85em'}}>Why are you still all the way down here? <Link to="/signup" className="orangeLink">Grab a slice and change your life!</Link></p> */}

      </Container>
    </Segment>

    <Segment inverted vertical style={{ padding: '5em 0em' }}>
      <Container>
        <Grid divided inverted stackable>
          <Grid.Row>
            {/* <Grid.Column width={3}>
              <Header inverted as='h4' content='About' />
              <List link inverted>
                <List.Item as='a'>Sitemap</List.Item>
                <List.Item as='a'>Contact Us</List.Item>
                <List.Item as='a'>Religious Ceremonies</List.Item>
                <List.Item as='a'>Gazebo Plans</List.Item>
              </List>
            </Grid.Column>
            <Grid.Column width={3}>
              <Header inverted as='h4' content='Services' />
              <List link inverted>
                <List.Item as='a'>Banana Pre-Order</List.Item>
                <List.Item as='a'>DNA FAQ</List.Item>
                <List.Item as='a'>How To Access</List.Item>
                <List.Item as='a'>Favorite X-Men</List.Item>
              </List>
            </Grid.Column> */}
            <Grid.Column width={16}>
              <Link to="/">
                <Header as='h4' inverted>
                  <Image src={"/logos/Salus_Logo_wide_w.png"} style={{ margin: 0, width: 'auto', height: 'auto', maxWidth: '300px' }} />
                </Header>
              </Link>
              <p>
                © SALUS 2020
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    </Segment>
  </AppContainer>
);

const mapStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser
});

const mapDispatchToProps = dispatch => ({
  signOutStart: () => dispatch(signOutStart())
});


export default connect(mapStateToProps, mapDispatchToProps)(HomePage);