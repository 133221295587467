import React from 'react';
import { 
  Container, Segment, Header, Divider, Button, Grid, List, Breadcrumb, 
  Dropdown, Icon, Checkbox, Modal, Form, Message 
} from 'semantic-ui-react';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { selectCurrentCompany } from '../../redux/company/company.selectors';
import { selectCurrentEmployees, selectEmployeesErrors, selectEmployeesLoading } from '../../redux/employees/employees.selectors';
import { deleteEmployeeStart, updateEmployeeStart, updateEmployeeFailure, getEmployeesStart } from '../../redux/employees/employees.actions';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';
import { selectCurrentResponses } from '../../redux/surveys/surveys.selectors';
import { getPromptsStart } from '../../redux/surveys/surveys.actions';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner.component';
import TextLinkButton from '../text-link-button/TextLinkButton.component';
import renderCustomizedErrorMessage from '../../utils/renderCustomizedErrorMessage.utils';

class EmployeeEdit extends React.Component {
  state = {
    confirmDeleteOpen: false,

    employeeName: '',
    phoneNumber: '',
    email: '',
    shiftAssignment: ''
  }

  componentDidMount() {
    const { view, currentEmployees, getShiftsStart, currentCompany, getPromptsStart } = this.props;
    const employeeId = view.viewData.employeeId;
    const employee = currentEmployees[employeeId];
    if (employee) {
      this.setState({
        employeeName: employee.employeeName,
        phoneNumber: employee.phoneNumber,
        email: employee.email,
        shiftAssignment: employee.shiftAssignment
      });
    }
    getShiftsStart({ currentCompany });
    getPromptsStart(currentCompany);
  }

  handleSubmit = async event => {
    event.preventDefault();
    const { currentCompany, updateEmployeeStart, updateEmployeeFailure, view } = this.props;
    const { employeeName, phoneNumber, email, shiftAssignment } = this.state;
    const employeeId = view.viewData.employeeId;
    
    if ( !employeeName || !phoneNumber || !employeeId ) {
      updateEmployeeFailure({
        code: "custom/failed-update-employee",
        message: "Please fill out all required fields"
      })
      return;
    }

    updateEmployeeStart({ 
      employeeId,
      employeeData: {
        employeeName, 
        phoneNumber, 
        email, 
        shiftAssignment: shiftAssignment || "Unassigned" 
      },
      currentCompany
    });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSelectChange = (e, { name, value }) => {
    this.handleChange({
      ...e,
      target: {
        ...e.target,
        name,
        value
      }
    })
  }

  showConfirmDelete = () => this.setState({ confirmDeleteOpen: true });
  closeConfirmDelete = () => this.setState({ confirmDeleteOpen: false });

  renderSurveyResultsHistory = (employeeId) => {
    if (!employeeId) return null;
    const { currentResponses, currentPrompts } = this.props
    if (!currentResponses) return "No responses received yet.";
    const responseArray = Object.keys(currentResponses).map(id => currentResponses[id]);
    const employeeResponses = responseArray.filter(item => item.employeeId === employeeId && (item.parsedAnswer || item.flowStepId === "covid-19-q3" || !!item.flowVariables ));
    if (!employeeResponses.length) return "No responses received yet.";
    const sortedResponses = employeeResponses.sort((a,b) => b.responseTime.toMillis() - a.responseTime.toMillis());
    return (
      <List verticalAlign='middle'>

        {/* <Header as="h2"><Header.Subheader>June 2020</Header.Subheader></Header> */}

        {sortedResponses.map(response => {
          let flagIcon = "question circle";
          let flagColor = null;
          let flagLabel = "No response received";
          if (response.flowVariables) {
            if (response.flowVariables.endStatus === 'at-risk') {
              flagIcon = "exclamation triangle";
              flagColor = "orange";
            } else if (response.flowVariables.endStatus === 'cleared-for-work') {
              flagIcon = "check circle";
              flagColor = "green";
            } else if (response.flowVariables.endStatus === 'was-tested') {
              flagIcon = "check circle";
              flagColor = "green";
            }
            flagLabel = response.flowVariables.endStatusText;
            flagLabel = flagLabel.charAt(0).toUpperCase() + flagLabel.slice(1);
          } else {
            // Handle old cases
            if (response.parsedAnswer) {
              flagIcon = "exclamation triangle";
              flagColor = "orange";
              flagLabel = "Flagged risk factors";
            } else if (!response.parsedAnswer) {
              flagIcon = "check circle";
              flagColor = "green";
              flagLabel = "Cleared for work";
            }
          }

          return (
            <List.Item key={response.responseId}>
              <List.Icon name={flagIcon} color={flagColor} verticalAlign='middle' />
              <List.Content>
                <List.Header>{moment(new Date(response.responseTime.toDate())).format("MMMM Do YYYY")}</List.Header>
                <List.Description as='a'>{flagLabel} at {moment(new Date(response.responseTime.toDate())).format("h:mm A z")}</List.Description>
              </List.Content>
            </List.Item>
          );
        })}

        {/* <Divider hidden />

        <Button content="Show more results" size="small" compact /> */}

        <Divider hidden />

      </List>
    );
  }

  render() {
    const { 
      setCurrentView, view, currentEmployees, deleteEmployeeStart, currentCompany, employeesLoading, employeesErrors,
      shiftsLoading, currentShifts
    } = this.props;
    const { confirmDeleteOpen, employeeName, phoneNumber, email, shiftAssignment } = this.state;
    const employeeId = view.viewData.employeeId;
    if (!currentEmployees) return null;
    const employee = currentEmployees[employeeId];
    return (
      <Container>

        <React.Fragment>
          {(employee && !employeesLoading) ? 
          (<Modal size="small" open={confirmDeleteOpen} onClose={this.closeConfirmDelete}>
            <Modal.Header><Header color="red"><Icon name="exclamation triangle" color="red"/>Confirm Delete Employee</Header></Modal.Header>
            <Modal.Content style={{ textAlign: 'center' }}>
              <p>Are you sure you want to delete this employee?</p>
              <Header as="h2"><Header.Subheader>{employee.employeeName}</Header.Subheader></Header>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={this.closeConfirmDelete} disabled={!!employeesLoading}>Cancel</Button>
              <Button
                negative
                icon='trash'
                labelPosition='right'
                content='Delete Employee'
                onClick={ () => deleteEmployeeStart({ currentCompany, currentEmployees, employeeId })}
                disabled={!!employeesLoading}
                loading={!!employeesLoading}
              />
            </Modal.Actions>
          </Modal>) : null}

          <Breadcrumb>
            <Breadcrumb.Divider icon='left chevron' />
            <Breadcrumb.Section link onClick={() => setCurrentView("EmployeeList")}>Back</Breadcrumb.Section>
          </Breadcrumb>

          <Header as="h1">
            <Header.Content>Edit Employee</Header.Content>
          </Header>

          {(employee && !employeesLoading) ? (

          <Segment.Group>
            <Segment>
              <Header>
                <Header.Content>{employee.employeeName}</Header.Content>
                {/* <Header.Subheader><Icon name='exclamation triangle' color="orange" />Flagged risk factors 14 mins ago</Header.Subheader> */}
              </Header>
            </Segment>

            <Segment>

              <Grid columns={2} divided>
                <Grid.Row>
                  <Grid.Column>

                    <Form size="large" onSubmit={this.handleSubmit} loading={!!employeesLoading}>
                      <Header>Employee Information</Header>
                      <Form.Input
                        fluid
                        autoComplete="off"
                        icon='user' 
                        iconPosition='left' 
                        placeholder='Employee Name' 
                        required
                        name="employeeName" 
                        value={employeeName}
                        onChange={this.handleChange} 
                      />
                      <Form.Field>
                        <PhoneInput 
                          required
                          autoComplete="off"
                          defaultCountry="US"
                          name="phoneNumber"
                          value={phoneNumber}
                          placeholder='Cell Phone Number' 
                          onChange={(newValue) => this.setState({ phoneNumber: newValue })} 
                        />
                      </Form.Field>

                      <Form.Input 
                        fluid
                        autoComplete="off"
                        icon='mail' 
                        iconPosition='left' 
                        placeholder='Email Address (Optional)' 
                        name="email" 
                        value={email}
                        onChange={this.handleChange} 
                      />

                      <Dropdown
                        search
                        selection
                        fluid
                        loading={!!shiftsLoading}
                        autoComplete="off"
                        name="shiftAssignment"
                        placeholder="Shift Assignment (Optional)"
                        header='Assign Shift'
                        options={
                          !currentShifts ? [{
                            key: "Unassigned",
                            value: "Unassigned",
                            text: "Unassigned"
                          }] : (
                            [ 
                              {
                                key: "Unassigned",
                                value: "Unassigned",
                                text: "Unassigned"
                              },
                              ...Object.keys(currentShifts).map(shiftId => ({
                                key: currentShifts[shiftId].shiftName,
                                value: shiftId,
                                text: currentShifts[shiftId].shiftName
                              }))
                            ]
                          )
                        }
                        value={shiftAssignment || "Unassigned"}
                        onChange={this.handleSelectChange} 
                      />

                      <div style={{ paddingTop: '0.5em' }}>
                        <i>Don't see the shift you're looking for?</i> <TextLinkButton onClick={() => setCurrentView("ShiftCreate")}>Create a new shift instead</TextLinkButton>
                      </div>
                      
                    </Form>

                    {
                      employeesErrors ? (
                        <Message error>
                          <Message.Header>Could not add employee.</Message.Header>
                          { renderCustomizedErrorMessage(employeesErrors.error.code, employeesErrors.error.message) }
                        </Message>
                      ) : null
                    }
                    
                    {/* <Divider hidden />

                    <Header>Notification Settings</Header>

                    <Header as='h4'>
                      <Icon name='user' />
                      <Header.Content>
                        Current manager is {' '}
                        <Dropdown
                          inline
                          header='Select Manager'
                          options={options2}
                          defaultValue={options2[0].value}
                        />
                      </Header.Content>
                    </Header>

                    <Checkbox label='Automatically send surveys' checked /> */}

                  </Grid.Column>

                  <Grid.Column>

                    <Header>Survey Results History</Header>

                    {this.renderSurveyResultsHistory(employeeId)}

                    </Grid.Column>

                </Grid.Row>
              </Grid>

            </Segment>
            <Segment secondary>
              <Button color="green" content="Save Changes" labelPosition="left" icon="check" onClick={this.handleSubmit} loading={!!employeesLoading} disabled={!!employeesLoading} />
              <Button color="red" content="Delete Employee" labelPosition="right" icon="trash alternate" onClick={this.showConfirmDelete} disabled={!!employeesLoading} />
            </Segment>
          </Segment.Group>
          ) : null}

          {(employeesLoading) ? <LoadingSpinner message="Loading ..." /> : (!employee ? "Employee not found" : null) }

        </React.Fragment>
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  employeesErrors: selectEmployeesErrors,
  currentEmployees: selectCurrentEmployees,
  currentCompany: selectCurrentCompany,
  employeesLoading: selectEmployeesLoading,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading,
  currentResponses: selectCurrentResponses
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  deleteEmployeeStart: ({ currentCompany, currentEmployees, employeeId }) => 
    dispatch(deleteEmployeeStart({ currentCompany, currentEmployees, employeeId })),
  updateEmployeeStart: ({ employeeId, employeeData, currentCompany }) => 
    dispatch(updateEmployeeStart({ employeeId, employeeData, currentCompany })),
  updateEmployeeFailure: (error) => dispatch(updateEmployeeFailure(error)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany })),
  getPromptsStart: (currentCompany) => dispatch(getPromptsStart(currentCompany))
});

export default connect(mapStateToProps, mapDispatchToProps)(EmployeeEdit);