import React from 'react';
import { Container, Segment, Header, Button, List, Icon, Label, Message } from 'semantic-ui-react';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectCurrentViewWithData } from '../../redux/view/view.selectors';
import { setCurrentView } from '../../redux/view/view.actions';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';
import { selectCurrentShifts, selectShiftsLoading, selectShiftsErrors } from '../../redux/shifts/shifts.selectors';
import { selectCurrentCompany } from '../../redux/company/company.selectors';

import { firestore } from '../../firebase/firebase.utils';

import { selectSurveysLoading, selectCurrentSurveys, selectSurveysErrors, selectCurrentPrompts, selectCurrentResponses } from '../../redux/surveys/surveys.selectors';
import { toggleSurveyStart, manualSurveyStart, getPromptsStart } from '../../redux/surveys/surveys.actions';

class ResponseList extends React.Component {

  componentDidMount() {
    const { currentCompany, getShiftsStart } = this.props;
    getShiftsStart({ currentCompany });
    this.subscribeToResponses(currentCompany);
  }

  subscribeToResponses = (currentCompany) => {
    const { getPromptsStart } = this.props;
    const companyId = currentCompany.companyId;
    console.log(`Subscribing to responses for company ${companyId}`);
    this.unsubscribeToResponses = firestore.collection('responses').where("linkedCompany", "==", companyId).onSnapshot(function(querySnapshot) {
      console.log("Responses received");
      getPromptsStart(currentCompany);
    });
  }

  componentWillUnmount(){
    console.log("Unsubscribing from responses");
    this.unsubscribeToResponses();
  }

  buildTemplateShiftKey = (templateId, shiftId) => {
    return `${templateId}--${shiftId}`;
  }

  toggleScheduledSurvey = (templateId, shiftId, nextStatus) => {
    const { toggleSurveyStart } = this.props;
    toggleSurveyStart({ templateId, shiftId, nextStatus });
  }

  renderShiftSublist = (shiftList) => {
    const { surveysLoading, manualSurveyStart, currentSurveys, currentPrompts, setCurrentView } = this.props;
    const shiftIds = Object.keys(shiftList);
    const templateId = "covid-19-survey";
    let surveyIds = [];
    if (currentSurveys) {
      let surveysToSort = Object.keys(currentSurveys).map(id => currentSurveys[id]);
      surveysToSort = surveysToSort.filter(survey => !!survey.lastPromptedTime); // Drop surveys without a prompted time
      const sortedSurveys = surveysToSort.sort((a,b) => b.lastPromptedTime.toMillis() - a.lastPromptedTime.toMillis());
      surveyIds = sortedSurveys.map(survey => survey.surveyId);
    }

    return (
      <List divided relaxed verticalAlign='middle' animated>
        {!surveyIds.length ? <Message>Looks like you haven't activated any surveys yet.</Message> : (
        surveyIds.map(surveyId => {
          const shiftId = currentSurveys[surveyId].linkedShift;
          const promptId = currentSurveys[surveyId].lastPromptId;
          if (!promptId) return <Message key={surveyId}>Looks like no surveys have been sent yet. Wait for an automatic send or trigger one manually.</Message>;
          const promptType = currentSurveys[surveyId].lastPromptType;
          const promptTime = currentSurveys[surveyId].lastPromptedTime;
          const isScheduled = currentSurveys[surveyId].isEnabled;
          if (!currentPrompts) return null;
          const lastPrompt = currentPrompts[promptId];
          if (!lastPrompt) return null;
          const noResponse = lastPrompt.notifiedEmployeeIds || [];
          const clearedForWork = lastPrompt.clearedForWork || [];
          const atRisk = lastPrompt.employeesAtRisk || [];
          // const showResponseFlags = shiftList[shiftId].shiftName === "COVID-19 Testing Reminder" ? false : true;
          const showResponseFlags = true;
          const shiftSel = shiftList[shiftId];
          if (!shiftSel) return null;
          return (
            <List.Item key={surveyId}>
              { showResponseFlags ? (
                <List.Content floated='right'>
                  <Button primary style={{ position: 'relative' }} onClick={() => setCurrentView("ResponseView", { promptId: promptId })}>View Responses
                  {/* <Label circular color="red" className="buttonCircleNotification">9</Label> */}
                  </Button>
                </List.Content>
              ) : null }
              {/* <List.Content floated='right'>
              { surveysLoading ? (<Icon loading name='spinner' />) : (
                <React.Fragment>
                <Button 
                  onClick={() => this.toggleScheduledSurvey(templateId, shiftId, !isScheduled)}
                  content={isScheduled ? "Disable" : "Enable"}
                  color={isScheduled ? "green" : "blue"}
                  icon={isScheduled ? "check" : "plus"}
                  labelPosition="left"
                  style={{ float: 'right' }}
                />
                { isScheduled ? (
                  <Button 
                    onClick={() => manualSurveyStart(templateShiftKey)}
                    style={{ float: 'right', clear: 'both', marginTop: '3px' }}
                    content={"Manual Survey"}
                    size="mini"
                    compact
                    // color={isScheduled ? "green" : "blue"}
                    disabled={!shiftList[shiftId].assignedEmployees.length}
                    icon="send"
                    labelPosition="left"
                  />
                ) : null}
                </React.Fragment>
              )}
              </List.Content> */}
              <List.Icon name="conversation" size="large"/>
              <List.Content>
                <List.Header>
                  {shiftSel.shiftName}
                  <div className="shiftDescriptionWrapper">
                    {!!promptTime ? (
                      <Label size="mini" style={{ marginLeft: '3px' }} image basic>
                        <Icon name='stopwatch' />
                        Last Triggered ({promptType.charAt(0).toUpperCase() + promptType.slice(1)})
                        <Label.Detail>{moment(new Date(promptTime.toDate())).format("MMMM Do YYYY, h:mm A z")}</Label.Detail>
                      </Label>
                    ) : null}
                    {/* <Label size="mini" style={{ marginLeft: '3px' }} image basic>
                      <Icon name='stopwatch' />
                      Starts
                      <Label.Detail>{moment(shiftList[shiftId].shiftStart,"HH:mm").tz(shiftList[shiftId].shiftTimezone).format("h:mm A z")}</Label.Detail>
                    </Label>
                    { shiftList[shiftId].shiftSendAuto ? (
                      <Label size="mini" color={isScheduled ? "green" : null} style={{ marginLeft: '3px' }} image basic={isScheduled ? false : true}>
                        <Icon name='send' />
                        Autosend
                        <Label.Detail>{moment(shiftList[shiftId].shiftStart,"HH:mm").tz(shiftList[shiftId].shiftTimezone).subtract(+shiftList[shiftId].shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                      </Label> 
                  ) : null }*/}
                  </div>
                </List.Header>

                { showResponseFlags ? (
                  <List.Description>

                  {atRisk.length ? (
                    <Label color="orange" size="mini"><Icon name='exclamation triangle' />{atRisk.length} at Risk</Label>
                  ) : null}

                  {noResponse.length - atRisk.length - clearedForWork.length ? (
                    <Label color={null} size="mini"><Icon name='circle question' />{noResponse.length - atRisk.length - clearedForWork.length} No Response</Label>
                  ) : null}

                  {clearedForWork.length ? (
                    <Label color="green" size="mini"><Icon name='check' />{clearedForWork.length} Clear for Work</Label>
                  ) : null}

                  </List.Description>
                ) : <List.Description>Contact <a href="mailto:support@salussms.com">support@salussms.com</a> for the archived responses</List.Description> }

                <List.Description>{`${shiftSel.assignedEmployees.length || "No"} assigned employees`}</List.Description>
              </List.Content>
            </List.Item>
          )
        })
        )}
      </List>
    );
  }

  renderShiftList = () => {
    const { currentShifts } = this.props;
    if (!currentShifts) {
      return null;
    } else {
      const currentShiftIds = Object.keys(currentShifts);
      return (
        <React.Fragment>
        {currentShiftIds.length ? (
          this.renderShiftSublist(currentShifts)
        ) : (
          <Message>Looks like you haven't received any survey responses yet. Keep in mind that responses can take a few minutes to appear.</Message>
        )}
        </React.Fragment>
      );
    }
  }

  render() {
    const { shiftsLoading, shiftsErrors, surveysErrors, surveysLoading } = this.props;

    return (
      <Container>

        <Header as="h1">
          <Header.Content>Manage Responses</Header.Content>
        </Header>

        <Segment.Group>
          <Segment>
            <Header>
              <Header.Content>Survey Responses</Header.Content>
            </Header>
          </Segment>

          <Segment loading={shiftsLoading || surveysLoading}>
            <Header>
              <Header.Content>COVID-19 Self-Screening SMS Survey <Label color="black">Automated</Label></Header.Content>
              <Header.Subheader>Enable automated surveys for each shift as needed</Header.Subheader>
            </Header>
            {this.renderShiftList()}
          </Segment>

          {/* <Segment secondary>
            <Button color="green" 
              content={
                <span>Save Changes
                <Label circular color="red" className="buttonCircleNotification">1</Label>
                </span>
              } 
              onClick={() => initiateManualSurvey()}
              labelPosition="left" icon="check" 
            />
          </Segment> */}
        </Segment.Group>

        {
          shiftsErrors ? (
            <Message error>
              <Message.Header>Could not load shifts.</Message.Header>
              <Message.Content>Refresh, or contact hello@SalusSMS.com for support.</Message.Content>
            </Message>
          ) : null
        }

        {
          surveysErrors ? (
            <Message error>
              <Message.Header>Could not load surveys.</Message.Header>
              <Message.Content>Refresh, or contact hello@SalusSMS.com for support.</Message.Content>
            </Message>
          ) : null
        }
        
      </Container>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  view: selectCurrentViewWithData,
  currentCompany: selectCurrentCompany,
  currentShifts: selectCurrentShifts,
  shiftsErrors: selectShiftsErrors,
  shiftsLoading: selectShiftsLoading,
  surveysLoading: selectSurveysLoading,
  currentSurveys: selectCurrentSurveys,
  surveysErrors: selectSurveysErrors,
  currentPrompts: selectCurrentPrompts,
  currentResponses: selectCurrentResponses
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany })),
  toggleSurveyStart: ({ templateId, shiftId, nextStatus }) => dispatch(toggleSurveyStart({ templateId, shiftId, nextStatus })),
  manualSurveyStart: (surveyId) => dispatch(manualSurveyStart(surveyId)),
  getPromptsStart: (linkedCompany) => dispatch(getPromptsStart(linkedCompany))
});

export default connect(mapStateToProps, mapDispatchToProps)(ResponseList);