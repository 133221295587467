import React from 'react'
import _ from 'lodash'
import { Button, Header, Modal, Input, List, Label, Placeholder, Icon, Segment, Message } from 'semantic-ui-react'
import TextLinkButton from '../text-link-button/TextLinkButton.component';
import moment from 'moment-timezone';

import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { setCurrentView } from '../../redux/view/view.actions';
import { selectCurrentShifts, selectShiftsLoading } from '../../redux/shifts/shifts.selectors';
import { getShiftsStart } from '../../redux/shifts/shifts.actions';

const initialSearchState = {
  searchText: '',
  loadingSearch: false,
  searchResults: null
}

class ShiftAssignEmployeeModal extends React.Component {
  state = {
    ...initialSearchState
  }

  renderEmployeeSublist = (employeeList) => {
    const employeeIds = Object.keys(employeeList);
    const { assignedIds, toggleAssignId } = this.props;
    return (
      <List divided verticalAlign='middle' animated size="large">
        { employeeIds.map(employeeId => (
          <List.Item key={employeeId}>
            <List.Content floated='right'>
              <Button 
                color={assignedIds.includes(employeeId) ? "green" : "blue"}
                icon={assignedIds.includes(employeeId) ? "check" : "plus"}
                labelPosition='left'
                content={assignedIds.includes(employeeId) ? "Unassign" : "Assign"}
                onClick={() => toggleAssignId(employeeId)}
              />
            </List.Content>
            <List.Icon name="user circle" size="large"/>
            <List.Content>
              {employeeList[employeeId].employeeName}
            </List.Content>
          </List.Item>
        )) }
      </List>
    );
  }

  renderEmployeeList = () => {
    const { currentEmployees, currentShifts, shiftsLoading, employeesLoading } = this.props;
    const { searchResults } = this.state;
    if (!currentEmployees) {
      if (!!employeesLoading) return null;
      return <Message>Looks like you haven't added any employees yet.</Message>;
    } else {

      // Split into Assigned and Unassigned Employees by assigned shift Id
      let employeesByShift = {};
      const employeeIds = Object.keys(currentEmployees);
      employeeIds.forEach(employeeId => {
        if (!searchResults || searchResults.includes(employeeId)) {        
          const employee = currentEmployees[employeeId];
          employeesByShift[employee.shiftAssignment] = { 
            ...employeesByShift[employee.shiftAssignment],
            [employeeId]: employee 
          };
        }
      });
      const unassignedEmployees = employeesByShift["Unassigned"] || {};
      const assignedShifts = Object.keys(employeesByShift).filter(shift => shift !== "Unassigned");

      return (
        <React.Fragment>
        {assignedShifts.length ? (
          <React.Fragment>
          <Header>Assigned Employees</Header>
          { assignedShifts.map(shiftId => (
            <React.Fragment key={shiftId}>
            { (!currentShifts || !!shiftsLoading) ? (
              <Placeholder>
                <Placeholder.Header>
                  <Placeholder.Line />
                  <Placeholder.Line />
                </Placeholder.Header>
              </Placeholder>
            ) : (
              <Header as="h2">
                <Header.Subheader>
                {currentShifts[shiftId].shiftName}
                  <Label size="mini" style={{ marginLeft: '6px' }} image basic>
                    <Icon name='stopwatch' />
                    Starts
                    <Label.Detail>{moment.tz(currentShifts[shiftId].shiftStart,"HH:mm",currentShifts[shiftId].shiftTimezone).format("h:mm A z")}</Label.Detail>
                  </Label>
                  { currentShifts[shiftId].shiftSendAuto ? (
                    <Label size="mini" color="green" style={{ marginLeft: '6px' }} image>
                      <Icon name='send' />
                      Autosend
                      <Label.Detail>{moment.tz(currentShifts[shiftId].shiftStart,"HH:mm",currentShifts[shiftId].shiftTimezone).subtract(+currentShifts[shiftId].shiftNotificationTime, 'minutes').format("h:mm A z")}</Label.Detail>
                    </Label>
                  ) : null }
                </Header.Subheader>
              </Header>
            ) }
            {this.renderEmployeeSublist(employeesByShift[shiftId])}
            </React.Fragment>
          )) }
          </React.Fragment>
        ) : null}
        {Object.keys(unassignedEmployees).length ? (
          <React.Fragment>
          <Header>Unassigned Employees</Header>
          {this.renderEmployeeSublist(unassignedEmployees)}
          </React.Fragment>
        ) : null}
        </React.Fragment>
      );
    }
  }

  handleSearchChange = event => {
    const searchVal = event.target.value;
    this.setState({ loadingSearch: true, searchText: searchVal });

    const { currentEmployees } = this.props;
    if (searchVal.length < 1 || !currentEmployees) return this.setState(initialSearchState);
    const employeeKeys = Object.keys(currentEmployees).map(key => ({ attribute: currentEmployees[key].employeeName, id: key }));

    const re = new RegExp(_.escapeRegExp(searchVal), 'i')
    const isMatch = (result) => re.test(result.attribute);
    const searchResults = _.filter(employeeKeys, isMatch).map(res => res.id);
    this.setState({
      loadingSearch: false,
      searchResults: searchResults,
    });
  }

  handleChange = event => {
    const { value, name } = event.target;
    this.setState({ [name]: value });
  }

  handleSubmit = () => {
    const { onClose } = this.props;
    this.setState(initialSearchState);
    // onSubmit(this.state.assignedIds);
    onClose();
  }

  render() { 
    const { isOpen,  onClose, employeesLoading, setCurrentView } = this.props;
    const { searchText, loadingSearch } = this.state;
    return (
      <Modal open={isOpen} onClose={onClose} centered={false}>
        <Modal.Header>Assign Employee to Shift 1</Modal.Header>
        <Modal.Content>

          <p>Search for an existing employee by name using the search box below</p>

          <Input 
            fluid 
            loading={loadingSearch}
            size="large"
            autoComplete="off"
            icon='search' 
            iconPosition='left' 
            placeholder='Search for employee by name ...' 
            required
            name="searchText" 
            value={searchText}
            onChange={this.handleSearchChange} 
          />

          <p style={{ paddingTop: '1em' }}><i>Are you trying to create a new employee?</i> <TextLinkButton onClick={() => setCurrentView("EmployeeCreate")}>Create a New Employee Here</TextLinkButton></p>

          <Segment basic loading={!!employeesLoading}>
            {this.renderEmployeeList()}
          </Segment>

        </Modal.Content>
        <Modal.Actions>
          {/* <Button color="red" content="Close" labelPosition="right" icon="cancel" onClick={onClose} /> */}
          <Button
            // positive
            // icon='check'
            // labelPosition='right'
            content={'Close'}
            onClick={this.handleSubmit}
            // disabled={!assignedIds.length}
            // loading={!!employeesLoading}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  // view: selectCurrentViewWithData,
  currentShifts: selectCurrentShifts,
  shiftsLoading: selectShiftsLoading
});

const mapDispatchToProps = dispatch => ({
  setCurrentView: (newView, viewData) => dispatch(setCurrentView(newView, viewData)),
  getShiftsStart: ({ currentCompany }) => dispatch(getShiftsStart({ currentCompany }))
});

export default connect(mapStateToProps, mapDispatchToProps)(ShiftAssignEmployeeModal);